import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
//import Select from 'react-select'
import MenuBar from '../components/MenuBar'
//import ShoppingCart from './ShoppingCart'
import Footer from '../components/Footer'
import Product from './giftshop/Product.json'
import Icon from '../assets/giftshop/chevron-right.png'
import { GiShoppingBag } from "react-icons/gi";
import ShoppingCart from './ShoppingCart'
//import '../shoppingcart.css'
//import { ImOpt } from 'react-icons/im'
//import ProductDetails from './ProductDetails'




const Giftshop2 = () => {
    const [cartsVisibilty, setCartVisible] = useState(false);

    const [items, setItems] = useState(Product)

    const [productsInCart, setProducts] = useState(
        JSON.parse(
            localStorage.getItem(
                "isinrimuseumwebapp"
            )
        ) || []
    );
    useEffect(() => {
        localStorage.setItem(
            "isinrimuseumwebapp",
            JSON.stringify(productsInCart)
        );
    }, [productsInCart]);
    const onQuantityChange = (productId, count) => {
        setProducts((oldState) => {
            const productsIndex =
                oldState.findIndex(
                    (item) =>
                        item.id === productId
                );
            if (productsIndex !== -1) {
                oldState[productsIndex].count =
                    count;
            }
            return [...oldState];
        });
    };

    const addProductToCart = (product) => {
        const newProduct = {
            ...product,
            count: 1,
        };
        setProducts([
            ...productsInCart,
            newProduct,
        ]);
    };

    const onProductRemove = (product) => {
        setProducts((oldState) => {
            const productsIndex =
                oldState.findIndex(
                    (item) =>
                        item.id === product.id
                );
            if (productsIndex !== -1) {
                oldState.splice(productsIndex, 1);
            }
            return [...oldState];
        });
    };


    const filterItems = (catItem) => {
        const updateItems = Product.filter((curItem) => {
            return curItem.category === catItem
        });
        setItems(updateItems)
    }

    return (
        <div >
            <MenuBar />
            <ShoppingCart
                visibilty={cartsVisibilty}
                products={productsInCart}
                onClose={() =>
                    setCartVisible(false)
                }
                onQuantityChange={
                    onQuantityChange
                }
                onProductRemove={onProductRemove}
            />

            {/* <ProductDetails 
                visibilty={productDetailVisibilty}
                products={productsInCart}
            /> */}


            <div className='mt-10 items-center justify-center text-center mx-4 xl:mx-40'>
                {/* <ShoppingCart /> */}
                <div>
                    <h1 className='text-[#F88603] mx-10 text-[55px] lg:text-[75px] lg:font-extrabold font-bold leading-[60px] tracking-[13px]'>Gift shop</h1>
                </div>
                <div className='lg:mx-[270px] text-[19px] lg:text-[12px] text-[#818285] py-10'>
                    <p>At Isi Nri Museum giftshop, we believe that every gift tells a story. Whether you’re celebrating a special occasion, expressing gratitude, or simply bringing a smile to a loved one’s face, we have the perfect selection of thoughtful and unique gifts to suit every need.
                    </p>

                    

                </div>
                <hr />
               
                <button
                        className='flex float-right pt-6 mr-10'
                        
                        onClick={() =>
                            setCartVisible(true)
                        }> <p className='text-[13px] text-[#2A564C] mr-2 mt-1 '>View cart</p>
                        <GiShoppingBag size={24} /> 
                        {productsInCart.length >
                            0 && (
                                <span className=' bg-[#2A564C] py-1 px-2 text-[#fdfdfd] text-[10px] rounded-full'>
                                    {
                                        productsInCart.length
                                    }
                                </span>
                            )}
                </button>
             
            </div>


            {/* {products} */}

            <div className='mt-20 gap-20 flex mx-auto justify-center pb-44 '>

                {/* {product navigator} */}
                <div className='hidden lg:block bg-[#FEFBF8] border border-[#818285]    px-4 py-10 w-[20%] h-[520px] text-[#818285] text-[14px] space-y-6'>
                    <h1 className='text-[#1A1C21] pb-2 border-b mr-[160px] font-bold text-[19px]'>Sort by:</h1>

                    <h1>Latest</h1>
                    <ul className='space-y-2 text-[12px]'>
                        <li className='flex cursor-pointer' onClick={() => setItems(Product)}>
                            New arrivals <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => filterItems("women's clothing")}>
                            Trending <img src={Icon} alt={'icon'} />
                        </li>
                    </ul>

                    <div>
                        <h1>Collections</h1>
                    </div>
                    <ul className='space-y-2 text-[12px]' >
                        <li className='flex cursor-pointer' onClick={() => filterItems("electronics")}>
                            Watches <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => filterItems("jewelery")}>
                            Earrings <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => filterItems("jewelery")}>
                            Pendants <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => filterItems("women's clothing")}>
                            women's wear <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => filterItems("men's clothing")}>
                            Men's wear <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => filterItems("jewelery")}>
                            Rings <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => filterItems("men's clothing")}>
                            Trending <img src={Icon} alt={'icon'} />
                        </li>
                        <li className='flex cursor-pointer' onClick={() => setItems(Product)}>
                            Customizable <img src={Icon} alt={'icon'} />
                        </li>
                    </ul>
                </div>

                <div className='grid md:grid-cols-3 grid-cols-2 gap-2 lg:gap-6 items-center justify-center mx-4 '>
                    {items.map((val) => (
                        <div className='items-center justify-center  text-center mx-auto  bg-[#FFFAF5] p-6 shadow-md h-[330px] ' key={val.id}>
                            <Link to={`/giftshop/${val.id}`}>
                                <img className='max-w-[120px] w-[120] h-[150px] p-4' src={val.image} alt={val.title} />
                                <div className='text-[#818285] text-left text-[13px]'>
                                    <p>{'$' + val.price}</p>
                                </div>
                                <div className='pt-4 pb-2'>
                                    <h5 className='text-left text-[#1A1C21] font-extrabold text-[14px]'>{val.title.substring(0, 12)}</h5>
                                    <p className='text-left text-[12px]'><i>View more...</i></p>
                                </div>
                            </Link>
                            <div>
                                <button
                                    className='border-[#2A564C] bg-[#2A564C] text-[10px] md:text-[12px] px-8  mt-4 md:px-14 py-2 rounded text-[#FDFDFD]'
                                    onClick={() =>
                                        addProductToCart(
                                            val
                                        )
                                    }
                                >Add to cart
                                </button>
                                <div  >

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Giftshop2